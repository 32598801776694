@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300&family=Readex+Pro&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #1c1917 !important;
    scroll-behavior: smooth !important;
}

.me {
    width: 550px;
    height: auto;
}
